@font-face {
  font-family: 'Produkt';
  src: url('./fonts/Produkt-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-LightItalic.eot');
  src: local('Gotham Light Italic'), local('Gotham-LightItalic'),
    url('./fonts/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-LightItalic.woff2') format('woff2'),
    url('./fonts/Gotham-LightItalic.woff') format('woff'),
    url('./fonts/Gotham-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-BoldItalic.eot');
  src: local('Gotham Bold Italic'), local('Gotham-BoldItalic'),
    url('./fonts/Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-BoldItalic.woff2') format('woff2'),
    url('./fonts/Gotham-BoldItalic.woff') format('woff'),
    url('./fonts/Gotham-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-XLight.eot');
  src: local('Gotham ExtraLight'), local('Gotham-XLight'),
    url('./fonts/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-XLight.woff2') format('woff2'),
    url('./fonts/Gotham-XLight.woff') format('woff'),
    url('./fonts/Gotham-XLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Thin.eot');
  src: local('Gotham Thin'), local('Gotham-Thin'),
    url('./fonts/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-Thin.woff2') format('woff2'),
    url('./fonts/Gotham-Thin.woff') format('woff'),
    url('./fonts/Gotham-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Book.eot');
  src: local('Gotham Book'), local('Gotham-Book'),
    url('./fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-Book.woff2') format('woff2'),
    url('./fonts/Gotham-Book.woff') format('woff'),
    url('./fonts/Gotham-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-ThinItalic.eot');
  src: local('Gotham Thin Italic'), local('Gotham-ThinItalic'),
    url('./fonts/Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-ThinItalic.woff2') format('woff2'),
    url('./fonts/Gotham-ThinItalic.woff') format('woff'),
    url('./fonts/Gotham-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-MediumItalic.eot');
  src: local('Gotham Medium Italic'), local('Gotham-MediumItalic'),
    url('./fonts/Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-MediumItalic.woff2') format('woff2'),
    url('./fonts/Gotham-MediumItalic.woff') format('woff'),
    url('./fonts/Gotham-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Ultra';
  src: url('./fonts/Gotham-UltraItalic.eot');
  src: local('Gotham Ultra Italic'), local('Gotham-UltraItalic'),
    url('./fonts/Gotham-UltraItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-UltraItalic.woff2') format('woff2'),
    url('./fonts/Gotham-UltraItalic.woff') format('woff'),
    url('./fonts/Gotham-UltraItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-BookItalic.eot');
  src: local('Gotham Book Italic'), local('Gotham-BookItalic'),
    url('./fonts/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-BookItalic.woff2') format('woff2'),
    url('./fonts/Gotham-BookItalic.woff') format('woff'),
    url('./fonts/Gotham-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-BlackItalic.eot');
  src: local('Gotham Black Italic'), local('Gotham-BlackItalic'),
    url('./fonts/Gotham-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-BlackItalic.woff2') format('woff2'),
    url('./fonts/Gotham-BlackItalic.woff') format('woff'),
    url('./fonts/Gotham-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Light.eot');
  src: local('Gotham Light'), local('Gotham-Light'),
    url('./fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-Light.woff2') format('woff2'),
    url('./fonts/Gotham-Light.woff') format('woff'),
    url('./fonts/Gotham-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Bold.eot');
  src: local('Gotham Bold'), local('Gotham-Bold'),
    url('./fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-Bold.woff2') format('woff2'),
    url('./fonts/Gotham-Bold.woff') format('woff'),
    url('./fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Medium.eot');
  src: local('Gotham Medium'), local('Gotham-Medium'),
    url('./fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-Medium.woff2') format('woff2'),
    url('./fonts/Gotham-Medium.woff') format('woff'),
    url('./fonts/Gotham-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Ultra';
  src: url('./fonts/Gotham-Ultra.eot');
  src: local('Gotham Ultra'), local('Gotham-Ultra'),
    url('./fonts/Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-Ultra.woff2') format('woff2'),
    url('./fonts/Gotham-Ultra.woff') format('woff'),
    url('./fonts/Gotham-Ultra.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-XLightItalic.eot');
  src: local('Gotham ExtraLight Italic'), local('Gotham-XLightItalic'),
    url('./fonts/Gotham-XLightItalic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-XLightItalic.woff2') format('woff2'),
    url('./fonts/Gotham-XLightItalic.woff') format('woff'),
    url('./fonts/Gotham-XLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Black.eot');
  src: local('Gotham Black'), local('Gotham-Black'),
    url('./fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Gotham-Black.woff2') format('woff2'),
    url('./fonts/Gotham-Black.woff') format('woff'),
    url('./fonts/Gotham-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Office';
  src: url('./fonts/GothamOffice-Regular.eot');
  src: local('Gotham Office Regular'), local('GothamOffice-Regular'),
    url('./fonts/GothamOffice-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GothamOffice-Regular.woff2') format('woff2'),
    url('./fonts/GothamOffice-Regular.woff') format('woff'),
    url('./fonts/GothamOffice-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Office';
  src: url('./fonts/GothamOffice-Italic.eot');
  src: local('Gotham Office Italic'), local('GothamOffice-Italic'),
    url('./fonts/GothamOffice-Italic.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GothamOffice-Italic.woff2') format('woff2'),
    url('./fonts/GothamOffice-Italic.woff') format('woff'),
    url('./fonts/GothamOffice-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham Office';
  src: url('./fonts/GothamOffice-Bold.eot');
  src: local('Gotham Office Bold'), local('GothamOffice-Bold'),
    url('./fonts/GothamOffice-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/GothamOffice-Bold.woff2') format('woff2'),
    url('./fonts/GothamOffice-Bold.woff') format('woff'),
    url('./fonts/GothamOffice-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Office';
  src: url('./fonts/GothamOffice-BoldItalic.eot');
  src: local('Gotham Office Bold Italic'), local('GothamOffice-BoldItalic'),
    url('./fonts/GothamOffice-BoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/GothamOffice-BoldItalic.woff2') format('woff2'),
    url('./fonts/GothamOffice-BoldItalic.woff') format('woff'),
    url('./fonts/GothamOffice-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-Bold.woff2') format('woff2'),
      url('./fonts/Lora-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-Italic.woff2') format('woff2'),
      url('./fonts/Lora-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-Medium.woff2') format('woff2'),
      url('./fonts/Lora-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-BoldItalic.woff2') format('woff2'),
      url('./fonts/Lora-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-Regular.woff2') format('woff2'),
      url('./fonts/Lora-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-MediumItalic.woff2') format('woff2'),
      url('./fonts/Lora-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-SemiBold.woff2') format('woff2'),
      url('./fonts/Lora-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-SemiBoldItalic.woff2') format('woff2'),
      url('./fonts/Lora-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Black.woff2') format('woff2'),
      url('./fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Italic.woff2') format('woff2'),
      url('./fonts/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-BoldItalic.woff2') format('woff2'),
      url('./fonts/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-BlackItalic.woff2') format('woff2'),
      url('./fonts/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('./fonts/Lato-Hairline.woff2') format('woff2'),
      url('./fonts/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Bold.woff2') format('woff2'),
      url('./fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Light.woff2') format('woff2'),
      url('./fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-LightItalic.woff2') format('woff2'),
      url('./fonts/Lato-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./fonts/Lato-Regular.woff2') format('woff2'),
      url('./fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('./fonts/Lato-HairlineItalic.woff2') format('woff2'),
      url('./fonts/Lato-HairlineItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

